import React, {useEffect, useState} from 'react';

const Counter = () => {
  const [time, setTime] = useState(0);

  useEffect(() => {
    let timerId = setInterval(() => setTime(time+1), 1000);
    return () => {
      clearInterval(timerId);
    };
  });

  return (
    <div>{time}</div>
  )
};

export default Counter;

import React from "react";
import './Info.sass';
import icon from '../../../assets/icon.png';
import Config from '../../../config';

const Info = () => {
    return (
        <div className="info">
            <div className="top">
                <div className="logo">
                    <img src={icon} alt="Picture" />
                </div>
                <div className="text">
                    Welcome to {Config.appName || 'Clover'}!<br/><br/>
                    This community has been setup to empower users to interact, communicate and message in real-time.
                </div>
            </div>
            <div className="text">
                Copyright &copy; {Config.brand || 'Honeyside'}
            </div>
        </div>
    );
};

export default Info;

import React, { useState, useRef, useEffect, useCallback } from 'react';
import Message from "./Message";
import { Lightbox } from "react-modal-image";
import Config from "../../../config";
import {useGlobal} from "reactn";
import './Messages.sass';
import {useDispatch, useSelector} from "react-redux";
import getMoreMessages from "../../../actions/getMoreMessages";
import Actions from "../../../constants/Actions";

const getUrl = message => {
    if (message.type === 'file') {
        return {
            medium: `${Config.url || ''}/api/files/${message.content}`,
            large: `${Config.url || ''}/api/files/${message.content}`,
        };
    }
    return {
        medium: `${Config.url || ''}/api/images/${message.content}/1024`,
        large: `${Config.url || ''}/api/images/${message.content}/2048`,
    }
}

const Messages = () => {
    const user = useGlobal('user')[0];
    const messages = useSelector(state => state.io.messages) || [];
    const room = useSelector(state => state.io.room);
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();

    const chat = useRef(null);

    const [open, setOpen] = useState(null);
    const memoSetOpen = useCallback(value => setOpen(value), []);

    let other = {
        firstName: 'A', lastName: 'A'
    };

    if (!room.isGroup && room.people) {
        room.people.forEach(person => {
            if (person._id !== user.id) other = person;
        });
    }

    const Messages = messages.map((message, index) => {
        return <Message
            key={message._id}
            message={message}
            previous={messages[index - 1]}
            next={messages[index + 1]}
            onOpen={memoSetOpen}
        />;
    });

    const onScroll = () => {
        if (chat.current.scrollTop === 0) {
            if (loading) return;
            setLoading(true);
            getMoreMessages({roomID: room._id, firstMessageID: messages[0]._id}).then(res => {
                dispatch({type: Actions.MORE_MESSAGES, messages: res.data.messages});
                setLoading(false);
            }).catch(err => {
                setLoading(false);
            });
        }
    };


    useEffect(() => {
        chat.current.scrollTop = chat.current.scrollHeight;
    }, [messages.length]);

    return (
        <div className="messages-wrapper" ref={chat} onScroll={onScroll}>
            <div className="messages-container">
                {open && (
                    <Lightbox
                        medium={getUrl(open).medium}
                        large={getUrl(open).large}
                        alt="Lightbox"
                        hideDownload={true}
                        onClose={() => setOpen(null)}
                    />
                )}
                {Messages}
            </div>
        </div>
    );
};

export default Messages;

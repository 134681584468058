import React, {useEffect} from 'react';
import {getGlobal, useGlobal} from 'reactn';
import { ToastProvider } from 'react-toast-notifications';
import './App.sass';
import {BrowserRouter as Router, Switch, Route, Redirect} from "react-router-dom";
import Home from './pages/Home';
import Login from './pages/Login';
import Config from './config';
import {useDispatch, useSelector} from "react-redux";


const App = () => {
    const io = useSelector(state => state.io.io);

    const token = useGlobal('token')[0];
    const setStartingPoint = useGlobal('entryPath')[1];

    const toHome = token && <Redirect to="/" />;
    const toLogin = !token && <Redirect to="/login" />;

    if (!['dark', 'light'].includes(Config.theme)) Config.theme = 'light';

    if (!window.loaded) {
        setStartingPoint(window.location.pathname);
        window.loaded = true
    }

    useEffect(() => {
        if (!io || !getGlobal().user || !token) return;
        let focusCount = 0;
        let interval = setInterval(() => {
            if (!document.hasFocus()) {
                focusCount++;
                if (focusCount === 10) {
                    io.emit('status', {status: 'away'});
                }
            }
            else if (focusCount !== 0) {
                focusCount = 0;
                io.emit('status', {status: 'online'});
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [io, token]);

    useEffect(() => {
        return () => {
            try {
                if (getGlobal().audioStream) getGlobal().audioStream.getTracks().forEach(track => track.stop());
            } catch (e) {}
            try {
                if (getGlobal().videoStream) getGlobal().videoStream.getTracks().forEach(track => track.stop());
            } catch (e) {}
        }
    }, []);

    return (
        <ToastProvider
            autoDismiss
            autoDismissTimeout={6000}
            placement="top-right"
        >
            <div className={`theme ${Config.theme}`}>
                <Router>
                    <Switch>
                        <Route exact path="/login">
                            {toHome}
                            {!toHome && <Login/>}
                        </Route>
                        <Route path="/">
                            {toLogin}
                            {!toLogin && <Home/>}
                        </Route>
                    </Switch>
                </Router>
            </div>
        </ToastProvider>
    );
}

export default App;

import React, {useRef, useState, Fragment} from 'react';
import "./BottomBar.sass";
import {
    FiSend,
    FiImage,
    FiSmile,
    FiPaperclip,
    FiMic,
    FiSlash,
    FiStopCircle,
} from "react-icons/fi";
import {useGlobal} from "reactn";
import moment from "moment";
import 'emoji-mart/css/emoji-mart.css';
import {Picker} from 'emoji-mart';
import message from "../../../actions/message";
import uploadImage from "../../../actions/uploadImage";
import uploadFile from "../../../actions/uploadFile";
import {useDispatch, useSelector} from "react-redux";
import Actions from "../../../constants/Actions";
import getRooms from "../../../actions/getRooms";
import MicRecorder from 'mic-recorder-to-mp3';
import Counter from "../../../components/Counter";

const recorder = new MicRecorder({
    bitRate: 128,
});

window.recorder = recorder;

const BottomBar = () => {
    const version = useGlobal('version')[0];
    const imageInput = useRef(null);
    const fileInput = useRef(null);

    const ref = useGlobal('ref')[0];
    const room = useSelector(state => state.io.room);
    const emojis = useSelector(state => state.emoji.sheet);
    const user = useGlobal('user')[0];

    const [text, setText] = useState('');
    const [record, setRecord] = useState(false);
    const [isPicker, showPicker] = useGlobal('isPicker');
    const [pictureRefs, addPictureRef] = useState([]);

    const dispatch = useDispatch();

    const sendMessage = () => {
        if (text.length === 0) return;
        const temporaryId = Math.random();
        message({temporaryId, roomID: room._id, authorID: user.id, content: text, contentType: 'text'});
        let newMessage = {
            _id: temporaryId, author: {...user, _id: user.id}, content: text, type: 'text', date: moment()
        };
        dispatch({type: Actions.MESSAGE, message: newMessage});
        setText('');
        showPicker(false);
    };

    const handleKeyPress = event => {
        showPicker(false);
        if (event.key === 'Enter') sendMessage();
    };

    // const sendImages = async images => {
    //     let tmpRefs = [];
    //     for (let i = 0; i < images.length; i++) {
    //         const image = images[i];
    //         tmpRefs.push(ref + i);
    //         const res = await uploadImage(image, ref + i);
    //         const temporaryId = Math.random();
    //         message({temporaryId: temporaryId, roomID: room._id, authorID: user.id, content: res.data.image.shieldedID, type: 'image'});
    //         let newMessage = {
    //             _id: temporaryId, author: {...user, _id: user.id}, content: res.data.image.shieldedID, type: 'image', date: moment()
    //         };
    //         dispatch({type: Actions.MESSAGE, message: newMessage});
    //     }
    //     addPictureRef([...pictureRefs, ...tmpRefs]);
    //     showPicker(false);
    // };

    const sendFiles = async files => {
        for (let i = 0; i < files.length; i++) {
            if ((files[i].size / (1024 * 1024)) > 10) return alert('File exceeds 10MB limit!');
        }
        let tmpRefs = [];
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            tmpRefs.push(ref + i);
            const res = await uploadFile(file, ref + i);
            const temporaryId = Math.random();
            message({temporaryId, roomID: room._id, authorID: user.id, content: res.data.file.shieldedID, type: 'file', fileID: res.data.file._id});
            let newMessage = {
                _id: temporaryId, author: {...user, _id: user.id}, content: res.data.file.shieldedID, type: 'file', date: moment(), file: res.data.file,
            };
            dispatch({type: Actions.MESSAGE, message: newMessage});
        }
        addPictureRef([...pictureRefs, ...tmpRefs]);
        showPicker(false);
    };

    const sendRecorder = async () => {
        setRecord(false);
        const [buffer, blob] = await recorder.stop().getMp3();
        const file = new File(buffer, 'me-at-thevoice.mp3', {
            type: blob.type,
            lastModified: Date.now()
        });
        await sendFiles([file]);
    };

    return (
        <div className="bottom-bar-conversation uk-flex uk-flex-middle">
            {/*<input*/}
            {/*    className="file-input"*/}
            {/*    type="file"*/}
            {/*    ref={imageInput}*/}
            {/*    accept="image/*"*/}
            {/*    multiple={true}*/}
            {/*    onChange={e => sendImages(e.target.files)}*/}
            {/*/>*/}
            {/*<div className="button image-attach" onClick={() => imageInput && imageInput.current && imageInput.current.click()}>*/}
            {/*    <FiImage/>*/}
            {/*</div>*/}
            {
                record ?
                  <Fragment>
                      <div className="button smile">
                          <Counter />
                      </div>
                      <div className="button image-attach" onClick={sendRecorder}>
                          <FiStopCircle />
                      </div>
                      <div className="button attach" onClick={() => recorder.stop().getMp3().then(() => setRecord(false))}>
                          <FiSlash />
                      </div>
                  </Fragment> :
                  <Fragment>
                      <div className="picker" hidden={!isPicker}>
                          <Picker
                            onSelect={emoji => setText(text + emoji.native)}
                            darkMode={false}
                            title="Emoji"
                            native={true}
                            set="facebook"
                          />
                      </div>
                      <div className="button smile" onClick={() => showPicker(!isPicker)}><FiSmile/></div>
                      <div className="button image-attach" onClick={() => recorder.start().then(() => setRecord(true))}>
                          <FiMic/>
                      </div>
                      <input
                        className="file-input"
                        type="file"
                        ref={fileInput}
                        multiple={true}
                        onChange={e => sendFiles(e.target.files)}
                      />
                      <div className="button attach" onClick={() => fileInput && fileInput.current && fileInput.current.click()}>
                          <FiPaperclip/>
                      </div>
                  </Fragment>
            }
            <input
                className="search-input"
                type="text"
                placeholder="Type something to send..."
                value={text}
                onChange={e => setText(e.target.value)}
                data-emoji-input="unicode"
                onKeyPress={handleKeyPress}
                onFocus={() => showPicker(false)}
            />
            <div className="button" onClick={sendMessage}><FiSend/></div>
        </div>
    );
}

export default BottomBar;

import axios from "axios";
import Config from "../config";

const login = (username, password) => {
    return axios({
        method: "post",
        url: (Config.url || '') + "/api/login",
        data: { username, password }
    });
};

export default login;

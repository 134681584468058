import React from 'react';
import "./TopBar.sass";
import placeholder from "../../../assets/placeholder.jpg";
import {FiMoreVertical, FiArrowLeft, FiMoreHorizontal, FiExternalLink} from 'react-icons/fi';
import {useGlobal} from "reactn";

const TopBar = ({back}) => {
  const allyHome = () => window.open("https://allynow.com", "_blank");
  const serviceAndroid = () => window.open("https://play.google.com/store/apps/details?id=io.logistically.service", "_blank");
  const serviceIos = () => window.open("https://apps.apple.com/us/app/service-ally/id1476329195", "_blank");

    return (
            <div className="top-bar uk-flex uk-flex-between uk-flex-middle">
                <div className="nav">
                    <div className="button" onClick={back}>
                        <FiArrowLeft/>
                    </div>
                </div>
                <div className="nav">
                    <div className="uk-inline">
                        <div className="button" type="button">
                            <FiMoreHorizontal/>
                        </div>
                        <div data-uk-dropdown="mode: click; offset: 5; boundary: .top-bar; pos: bottom-right">
                          <div className="link" onClick={allyHome}>Ally Home <div className="icon"><FiExternalLink/></div></div>
                          <div className="link" onClick={serviceAndroid}>Service Ally Android <div className="icon"><FiExternalLink/></div></div>
                          <div className="link" onClick={serviceIos}>Service Ally iOS <div className="icon"><FiExternalLink/></div></div>
                        </div>
                    </div>
                </div>
            </div>
    );
}

export default TopBar;
